import React from "react";
import PageLayout from "../../components/pageLayout/pageLayout";

function Reports() {
    return(
        <PageLayout
            page='Reports'
        >
            
        </PageLayout>
    )
}

export default Reports;