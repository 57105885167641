import React from "react";
import PageLayout from '../../components/pageLayout/pageLayout'

function Projects() {

    return(
        <>
            <PageLayout>
                
            </PageLayout>
        </>
    )
}

export default Projects;